const slotSizes = {
  "panorama_top": [[980, 240]],
  panorama: [[980, 240],[980, 400],[980, 360]],
  "panorama_widget": [[980, 240]],
  module: [[640, 360]],
  articlemodule: [[640, 360]],
  "articlemodule_widget": [[640, 360]],
  insider: [[300, 600]],
  outsider: [[300, 600],[300, 300],[300, 360],[300, 250]],
  mob: [[320, 320], [300, 250]],
  "mob_top": [[320, 320], [300, 250]],
  "mob_widget": [[320, 320]],
  "rich_media_premium": [[1920, 1080]],
  "mob_rich_media_premium": [[320, 480]]
};

export { slotSizes };
